//hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchStrumentiComponents } from "./actions";

import LayoutStrumenti from "../../components/UI/strumenti/LayoutStrumenti";
import StrumentiTitle from "../../components/UI/strumenti/strumentiTitle/StrumentiTitle";
import ToolBoxList from "../../components/UI/strumenti/toolBoxList/toolBoxList";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function Strumenti({ path }) {
  const dispatch = useDispatch();

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const toolsComponents = useSelector((state) => {
    return state.tools.components;
  });
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { status, errorData } = GetStatusAndErrorData("tools");

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const componentsMapping = {
    "fi-tools-title-description": StrumentiTitle,
    "fi-tools-catalogue": ToolBoxList,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        toolsComponents?.components && (
          <LayoutStrumenti
            heroImg={
              toolsComponents?.settings?.heroImage
                ? `${toolsComponents?.settings?.heroImage}:hero-img?$DEFAULT$`
                : ""
            }
          >
            {toolsComponents?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutStrumenti>
        )
      )}
    </>
  );
}

export default Strumenti;
