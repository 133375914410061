//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
// components
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import LayoutCompanyMap from "../../components/UI/companyMap/LayoutCompanyMap";
import GenericTitle from "../../components/UI/genericTemplate/title/GenericTitle";
import BuildingList from "../../components/UI/companyMap/buildingsList/BuildingList";
import { selectAggregatedDirection } from "../MyPage/selectors";

function CompanyMap({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.companyMap.components);
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("companyMap");

  const componentsMapping = {
    "fi-title": GenericTitle,
    "fi-mappa-aziendale": BuildingList,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <LayoutCompanyMap
            heroImg={
              components?.settings?.heroImage
                ? `${components?.settings?.heroImage}:hero-img?$DEFAULT$`
                : ""
            }
          >
            {components?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutCompanyMap>
        )
      )}
    </>
  );
}

export default CompanyMap;
