//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./FAQSlice";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// components
import NewsDocContainer from "../../components/UI/news/newsDetailContainer/newsDocContainer/NewsDocContainer";
import GenericTitle from "../../components/UI/genericTemplate/title/GenericTitle";
import EventDivider from "../../components/UI/event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import ContractContainer from "../../components/UI/contratto/contractContainer/ContractContainer";
import Image from "../../components/UI/Image/Image";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import FormazioneManagerialeBoxes from "../../components/UI/formazioneManageriale/formazioneManagerialeBoxes/formazioneManagerialeBoxes";
import StrumentiTitle from "../../components/UI/strumenti/strumentiTitle/StrumentiTitle";
import LayoutStrumenti from "../../components/UI/strumenti/LayoutStrumenti";
import GenericText from "../../components/UI/FAQ/text/GenericText";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function FAQHome({ path, type }) {
  const dispatch = useDispatch();

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.FAQHome.components);
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("FAQHome");

  const componentsMapping = {
    "fi-title": GenericTitle,
    "fi-image": Image,
    "fi-text": GenericText,
    "fi-tools-title-description": StrumentiTitle,
    "fi-contract-container": ContractContainer,
    "fi-divider": EventDivider,
    "fi-contratto-documents": NewsDocContainer,
    "fi-formazione-manageriale": FormazioneManagerialeBoxes,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <LayoutStrumenti
            heroImg={
              components?.settings?.heroImage
                ? `${components?.settings?.heroImage}:hero-img?$DEFAULT$`
                : ""
            }
          >
            {components?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    id={idx}
                    key={`${current.resourceType}-${idx}`}
                    template={type}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutStrumenti>
        )
      )}
    </>
  );
}

export default FAQHome;
