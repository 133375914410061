//hooks
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { fetchComponents } from "./AdsListSlice";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// components
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import LayoutStrumenti from "../../components/UI/strumenti/LayoutStrumenti";
import HomeAdsButton from "../../components/UI/offroCasa/homeAdsButton/homeAdsButton";
import HomeAdsCatalogue from "../../components/UI/offroCasa/homeAdsCatalogue/homeAdsCatalogue";
import { selectComponents, selectSettings } from "./selectors";
import { ADS_BUTTONS, ADS_CATALOG, MY_ADS_CATALOG } from "./constants";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function AdsList({ path, type }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const settings = useSelector(selectSettings());
  const components = useSelector(selectComponents());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && type && dispatch(fetchComponents({ path, token, type }));
  }, [path, type]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("AdsList");

  const componentsMapping = {
    [ADS_BUTTONS]: HomeAdsButton,
    [ADS_CATALOG]: HomeAdsCatalogue,
    [MY_ADS_CATALOG]: HomeAdsCatalogue,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components && (
          <LayoutStrumenti
            heroImg={
              settings?.heroImage
                ? `${settings?.heroImage}:hero-img?$DEFAULT$`
                : ""
            }
          >
            {React.Children.toArray(
              components?.map((current) => {
                const ReactGenericComponent =
                  componentsMapping[current.resourceType];
                return (
                  ReactGenericComponent && (
                    <ReactGenericComponent></ReactGenericComponent>
                  )
                );
              })
            )}
          </LayoutStrumenti>
        )
      )}
    </>
  );
}

export default AdsList;
