import PropTypes from "prop-types";
// style
import { Container } from "./style";

// components
import EventDescription from "./eventDescription/EventDescription";
import EventPlace from "./eventPlace/EventPlace";
import EventImage from "./eventImage/EventImage";
import FiText from "./fi-text/FiText";
import FaqAccordionList from "./faqAccordionList/faqAccordionList";
import FiButton from "./fi-button/FiButton";
import NewsDocContainer from "./newsDocContainer/NewsDocContainer";
import EventDivider from "./eventDivider/EventDivider";
import MainCarousel from "../../../../home/mainCarousel/MainCarousel";
import VideoPlayerSelector from "../../../../videoPlayerSelector/VideoComponent";
import { AudioPlayer } from "../../../../audioWave/AudioPlayer";
import ImageGallery from "../../../../ImageGallery/ImageGallery";

function LeftColumnContainer({ leftColumnComponents }) {
  const componentsMapping = {
    "fi-events/fi-event-info/fi-event-info-description": EventDescription,
    "fi-events/fi-event-info/fi-event-info-location": EventPlace,
    "fi-carousel": MainCarousel,
    "fi-image-gallery": ImageGallery,
    "fi-video": VideoPlayerSelector,
    "fi-audio": AudioPlayer,
    "fi-image": EventImage,
    "fi-text": FiText,
    "fi-faq-accordion-list": FaqAccordionList,
    "fi-button": FiButton,
    "fi-contratto-documents": NewsDocContainer,
    "fi-divider": EventDivider,
  };

  return (
    <Container>
      {leftColumnComponents?.map((element, idx) => {
        const ReactGenericComponent = componentsMapping[element.resourceType];
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              id={idx}
              key={`${element.resourceType}-${idx}`}
              elementData={element.data}
              template="event-details"
              data={element.data}
            />
          )
        );
      })}
    </Container>
  );
}

LeftColumnContainer.propTypes = {
  leftColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default LeftColumnContainer;
