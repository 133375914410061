import LayoutFormAnnunci from "../../components/UI/formAnnunci/LayoutFormAnnunci";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents, getDetail } from "./FormAnnuncioSlice";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import { getForm } from "./selectors";
import { useLocation } from "react-router-dom";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

export default function FormAnnuncio({ path }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const formLabels = useSelector(getForm());
  const components = useSelector((state) => state.FormAnnuncio.components);
  const detail = useSelector((state) => state.FormAnnuncio.detail);
  const token = useSelector((state) => state.login.activeToken);
  const coreLang = useSelector((state) => state.coreComponents.language);
  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
    dispatch(
      fetchComponents({
        path: "/" + coreLang + "/community-life/notice-board/new-ad",
        token,
        id,
      })
    );
    if (id) {
      dispatch(
        getDetail({
          path:
            "/" +
            coreLang +
            "/community-life/notice-board/offer-detail/" +
            id +
            "?modify=true",
          token,
        })
      );
    }
  }, [dispatch, path, token, id, coreLang]);
  ChangeTitleDoc(
    subjectCode,
    aggregatedDirection,
    components?.settings?.jcrTitle,
    path
  );
  const { status, errorData } = GetStatusAndErrorData("FormAnnuncio");
  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        formLabels?.data?.properties &&
        (!id || detail) && (
          <LayoutFormAnnunci
            heroImg={
              components?.settings?.heroImage
                ? `${components?.settings?.heroImage}:hero-img?$DEFAULT$`
                : ""
            }
            data={formLabels.data?.properties}
            detail={detail}
            id={id}
          />
        )
      )}
    </>
  );
}
